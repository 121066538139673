<template>
    <div class="page bg-white" id="reorganize">
        <!-- <uploader
                browse_button="browse_button"
                :url="this.$globalUrl() + 'music-common/commonFile/getPercentage'"
                chunk_size="3MB"
                :multi_selection="true"
                :max_retries="3"
                :filters="myfilters"
                :FilesAdded="filesAdded"
                :BeforeUpload="beforeUpload"
                :Error="error"
                :headers="myHeaders"
                :UploadComplete="uploadComplete"
                :FileUploaded="fileUploaded"
                :UploadProgress="uploadProgress"
                @inputUploader="inputUploader"
            />

                <el-button ref="plUpload"  type="primary" id="browse_button">替换</el-button> -->


        <!--展览-->
        <div class="exhibition">
            <el-collapse accordion v-model="catalogueNames">
                <el-collapse-item name="1">
                    <template slot="title">
                        <div class="flex_b_c wenojsnd" style="width: 100%">
                            <div class="exhibition-title">
                                <div class="xiaoshutiao"></div>
                                展览<span class="exhibition-select"><span v-if="exhibitionName"
                                                                        style="margin-right: 5px">·</span>{{ exhibitionName }}</span>
                            </div>
                            <div>
                                <el-button v-if="catalogueNames.length == 0" style="margin-right: 60px" size="mini"
                                           type="primary"
                                           icon="el-icon-search">查询
                                </el-button>
                                {{ catalogueNames.length == 0 ? '展开' : '收缩' }}
                            </div>
                        </div>
                    </template>
                    <el-row :gutter="20" style="margin-bottom: 20px">
                        <el-col :span="6">
                            <el-input v-model="catalogueName" maxlength="66" size="small" placeholder="请输入展览名称"
                                      clearable></el-input>
                        </el-col>
                        <el-col :span="3">
                            <el-button @click="queryGetCatalogue" style="margin-right: 60px" size="small" type="primary"
                                       icon="el-icon-search">查询
                            </el-button>
                        </el-col>
                    </el-row>
                    <div class="flex_b_c">
                        <div style="font-size: 50px; cursor: pointer;" @click="exhibitionOn">
                            <i :class="['el-icon-arrow-left', pageNo3 == 1 ? 'hui' : 'blue']"></i>
                        </div>
                        <div style="width: 100%;text-align: center;color: rgb(144,147,153)"
                             v-if="!exhibitionOpt.length">暂无数据
                        </div>
                        <div @click="catalogueSelect(item)" v-for="item in exhibitionOpt" :key="item.id"
                             :class="['checkBox', 'aidjbf', exhibitionVal == item.id ? 'select' : '']"
                             style="cursor: pointer;">
                            <div style="margin-right: 10px">·</div>
                            <el-tooltip placement="top">
                                <div slot="content">{{ item.exhibitionName }}</div>
                                <div class="wenojsnd">{{ item.exhibitionName }}</div>
                            </el-tooltip>
                        </div>
                        <div style="font-size: 50px;cursor: pointer;" @click="exhibitionTo">
                            <i :class="['el-icon-arrow-right', pageNo3 * pageSize3 >= total3 ? 'hui' : 'blue']"></i>
                        </div>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>

        <!--        目录-->
        <div class="exhibition">
            <el-collapse accordion v-model="activeNames">
                <el-collapse-item name="1">
                    <template slot="title">
                        <div class="flex_b_c wenojsnd" style="width: 100%">
                            <div class="exhibition-title">
                                <div class="xiaoshutiao"></div>
                                目录<span v-if="Object.values(exhibitionShuju).length"
                                        class="exhibition-select">{{ exhibitionShuju.directoryName }} / <el-button
                                    icon="el-icon-paperclip"
                                    type="text" size="mini"
                                    @click.native="handelRelevance(exhibitionShuju)">关联</el-button></span>
                            </div>
                            <div>
                                <el-button v-if="activeNames.length == 0" style="margin-right: 60px" size="mini"
                                           type="primary"
                                           icon="el-icon-search">查询
                                </el-button>
                                <el-button style="margin-right: 60px" size="mini" @click="exportReorganize">导出目录
                                </el-button>
                                {{ activeNames.length == 0 ? '展开' : '收缩' }}
                            </div>
                            <!--                            <el-button style="margin-right: 60px" size="mini" type="primary" icon="el-icon-search">查询</el-button>-->
                        </div>
                    </template>
                    <el-row :gutter="20" style="margin-bottom: 20px">
                        <el-col :span="6">
                            <el-input v-model="catalogueSrh" maxlength="66" size="small" placeholder="请输入目录名称"
                                      clearable></el-input>
                        </el-col>
                        <el-col :span="3">
                            <el-button @click="searchDirectoryList" style="margin-right: 60px" size="small"
                                       type="primary"
                                       icon="el-icon-search">查询
                            </el-button>
                        </el-col>
                    </el-row>
                    <div class="flex_b_c">
                        <div style="font-size: 50px; cursor: pointer;" @click="directoryOn">
                            <i :class="['el-icon-arrow-left', pageNo == 1 ? 'hui' : 'blue']"></i>
                        </div>
                        <div style="width: 100%;text-align: center;color: rgb(144,147,153)"
                             v-if="!catalogueList.length">暂无数据
                        </div>
                        <div @click="exhibitionSelect(item)" v-for="item in catalogueList" :key="item.id"
                             :class="['checkBox', 'flex_b_c', exhibitionShuju.id == item.id ? 'select' : '']"
                             style="cursor: pointer;">
                            <div style="width: 95%">
                                <div class="flex_b_c">
                                    <div class="wenojsnd">{{ item.directoryNo }}</div>
                                    <div>
                                        <el-button icon="el-icon-paperclip" type="text" size="mini"
                                                   @click="handelRelevance(item)">关联
                                        </el-button>
                                    </div>
                                </div>
                                <el-tooltip placement="top">
                                    <div slot="content">{{ item.directoryName }}</div>
                                    <div class="wenojsnd">{{ item.directoryName }}</div>
                                </el-tooltip>
                            </div>
                        </div>
                        <div style="font-size: 50px;cursor: pointer;" @click="directoryTo">
                            <i :class="['el-icon-arrow-right', pageNo * pageSize >= total ? 'hui' : 'blue']"></i>
                        </div>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>
        <div class="lbaiogao">
            <div style="margin-left: 10px" class="catalogueBox">
                <div class="exhibition-title">
                    <div class="xiaoshutiao"></div>
                    原文
                </div>
                <div class="flex_b_c">
                    <div>
                        <el-input style="width: 300px;margin-right: 10px" v-model="fileSrh" size="small"
                                  placeholder="请输入文件名称"
                                  maxlength="66" clearable></el-input>

                        <el-button @click="searchFileList" style="margin-right: 60px" size="small" type="primary"
                                   icon="el-icon-search">查询
                        </el-button>
                    </div>
                    <div>
                        <el-button size="small" type="success" plain v-if="hasPermission('reorganize:archive')"
                                   @click.native="archive()" :disabled="dataListSelections.length <= 0">归档
                        </el-button>
                        <el-button size="small" type="success" plain v-if="hasPermission('reorganize:batchArchive')"
                                   @click.native="batchArchive()" :disabled="directoryId == '' || fileList.length == 0">
                            一键归档
                        </el-button>
                        <!-- <el-button size="small"
                                               @click.native="getLog()" >日志
                                    </el-button> -->
                        <el-dropdown class="m_l1">
                            <el-button type="primary" size="small">
                                智能操作<i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item @click.native="download()" :disabled="dataListSelections.length <= 0">
                                    下载
                                </el-dropdown-item>
                                <el-dropdown-item @click.native="edit('move')"
                                                  :disabled="dataListSelections.length <= 0">移动
                                </el-dropdown-item>
                                <el-dropdown-item @click.native="edit('edit')"
                                                  :disabled="dataListSelections.length <= 0">修改
                                </el-dropdown-item>
                                <el-dropdown-item @click.native="del()" :disabled="dataListSelections.length <= 0">删除
                                </el-dropdown-item>
                                <!-- <el-dropdown-item @click.native="addArchive()"
                                                                  :disabled="dataListSelections.length <= 0">添加至归档任务
                                                </el-dropdown-item> -->
                            </el-dropdown-menu>
                        </el-dropdown>
                        <el-button-group class="m_l1">
                            <el-button size="small" plain icon="el-icon-refresh" @click="getFileList"></el-button>
                            <el-button size="small" plain icon="el-icon-setting" @click="setTb"></el-button>
                        </el-button-group>
                    </div>
                </div>
                <el-table :data="fileList" size="small" ref="tableBox" v-loading="loading" :element-loading-text="txt"
                          element-loading-spinner="el-icon-loading" @selection-change="selectionChangeHandle"
                          height="calc(100vh - 460px)"
                          class="table table3" @row-click="rowClick">
                    <!--                            @row-click="rowClick" @current-change="rowClick"-->
                    <el-table-column type="selection" width="50" fixed/>
                    <el-table-column fixed="right" label="操作" width="200">
                        <template slot-scope="scope">
                            <el-button size="mini" type="primary" plain @click="view(scope.row.id)">详情</el-button>
                            <Upload style="display: inline-block; margin-left: 10px" :uploadAskObj="uploadRequire"
                                    @getDataList="getDataList" @getPercentage="getPercentage"></Upload>
                            <!-- <el-button type="primary" @click="test(scope.row)">替换</el-button> -->
                            <!--<el-button size="mini" type="primary" plain @click="">替换</el-button>-->
                            <el-dropdown class="m_l1">
                                <el-button plain size="mini">
                                    更多<i class="el-icon-arrow-down el-icon--right"></i>
                                </el-button>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item @click.native="download(scope.row)">下载</el-dropdown-item>
                                    <el-dropdown-item @click.native="edit('move', scope.row)">移动</el-dropdown-item>
                                    <el-dropdown-item @click.native="edit('edit', scope.row)">修改</el-dropdown-item>
                                    <el-dropdown-item @click.native="del(scope.row)">删除</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                    <el-table-column v-for="(item, index) in config" :key="item.id" :prop="item.fieldEnname"
                                     :label="item.recordName"
                                     :show-overflow-tooltip="item.fieldEnname != 'sequenceNumber'"
                                     v-if="item.onShow == 0">
                        <template slot-scope="scope">
                            <el-input-number v-if="item.fieldEnname == 'sequenceNumber'" style="width: 100px"
                                             size="mini"
                                             v-model="scope.row.sequenceNumber" @change="handleChangeOrder(scope.row)"
                                             :min="1"
                                             :max="999999"
                                             v-noMoreClick></el-input-number>
                            <!--                            <div v-else>{{scope.row[item.fieldEnname]}}</div>-->
                            <div v-if="item.fieldEnname != 'sequenceNumber' && scope.row[item.fieldEnname]">
                                {{ scope.row[item.fieldEnname] }}
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @size-change="sizeChangeHandle2" @current-change="currentChangeHandle2"
                               :current-page="pageNo2"
                               :page-sizes="[10, 20, 50, 100]" :page-size="pageSize2" :total="total2" background
                               layout="total, sizes, prev, pager, next, jumper">
                </el-pagination>
            </div>
        </div>
        <!-- 上传进度弹窗 -->
        <el-dialog title="上传进度" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
            <!-- <el-progress :text-inside="true" :stroke-width="20"
              :percentage="percent"></el-progress> -->
            <el-progress style="margin-top: 5px" :stroke-width="10" :percentage="percentage"></el-progress>

        </el-dialog>
        <!--    日志    -->
        <Log ref="reorganizeLog"></Log>
        <!--列表设置拖拽排序弹窗-->
        <DraggablePop :moduleId="moduleId" :setShow="setShow" @getTbList="getTbList"></DraggablePop>
        <!-- 详情 -->
        <FileDetails ref="fileDetails" @refreshDataList="getFileList"></FileDetails>
        <!--关联-->
        <Relevance ref="relevance" @refreshDataList="getFileList"></Relevance>
        <!--修改、移动-->
        <EditForm ref="editForm" @refreshDataList="getFileList"></EditForm>

        <getTemplate ref="getTemplate" @refreshArchives="refreshArchives" @getFileList="getFileList"></getTemplate>
        <addArchive ref="addArchive" @refreshDataList="getFileList"></addArchive>
    </div>
</template>

<script>
    import DraggablePop from '@/components/draggable/draggablePop' // 导入排序弹窗
    import Upload from '@/components/Upload/MyUpload' // 上传
    import Uploader from "@/components/Upload/Uploader"; // 导入上传组件
    import FileDetails from '../documents/fileDetails' // 详情
    import Relevance from './relevance' // 关联
    import EditForm from './editForm' // 修改
    import Log from "../log"; // 上传日志
    import getTemplate from "../getTemplate"; // 获取档号规则
    import addArchive from "./addArchive"; // 获取档号规则
    export default {
        components: {DraggablePop, FileDetails, Relevance, EditForm, Upload, Uploader, Log, getTemplate, addArchive},
        data() {
            return {
                // 展览
                exhibitionOpt: [],
                exhibitionVal: '',
                exhibitionLabel: "",
                // 目录
                catalogueSrh: '',
                catalogueList: [],
                pageNo: 1,
                pageSize: 4,
                total: 0,
                // 原文
                fileSrh: '',
                fileList: [],
                dataListSelections: [],
                loading: false,
                pageNo2: 1,
                pageSize2: 10,
                total2: 0,
                moduleId: 0,   // 当前请求拖拽排序数据id
                setShow: false, // 是否显示列表设置拖拽排序弹窗
                pageNo3: 1,
                pageSize3: 4,
                total3: 0,
                //上传要求
                uploadRequire: {
                    btnName: '替换', // 按钮名称
                    list: [],
                    count: 1, // 上传文件数量 0表示不限制文件的数量
                    megaNum: 2048,
                    accept: "*",
                    uploadPath: "cultural",
                    uploadUrl: this.$globalUrl() + 'zyd-common/file/exhibitionUpload?uploadPath=catalogue',
                    percentageMethod: 'getPercentage',
                },
                directoryId: "",
                log: {
                    operModular: "1",//模块
                    operType: "1",//类型
                    operTerm: "",//内容
                    operSystem: "1",//结果
                },
                rows: [],
                fileVersionHistory: {
                    fileId: "",
                    url: "",
                    fileFormat: "",
                    fileSize: "",
                    fileName: "",
                    version: "1",
                    actualPath: ""
                },
                row: {},
                config: [],
                fileName: "",

                //展览
                catalogueShuju: {}, //展览选中数据
                catalogueNames: [], //控制是否展开
                catalogueName: '',//展览名称
                //展览分页
                cataloguePageNo: 1,
                cataloguePageSize: 4,
                catalogueTotal: 0,
                catalogueList1: [],

                exhibitionShuju: {}, //目录选中数据
                activeNames: [], //控制是否展开
                exhibitionName: '',//目录名称
                //展览分页
                exhibitionPageNo: 1,
                exhibitionPageSize: 4,
                exhibitionTotal: 0,
                exhibitionList: [],
                //进度条
                percentage: 0,
                percent: 0,
                percent1: 0,
                dialogVisible: false,
                //plupload上传
                up: {},
                files: [],
                uploading: false,
                myHeaders: {Authorization: sessionStorage.getItem('token'), satoken: sessionStorage.getItem('token')},
                myfilters: {
                    // mime_types :[{ title : "Image files", extensions : "jpg,gif,png,mp4,PDF,pdf,DOC,doc,avi" }],
                    // max_file_size : '400kb', //最大只能上传400kb的文件
                    prevent_duplicates: true //不允许选取重复文件},
                },
                flag: false,
                txt: "拼命加载中"
            }
        },
        // watch: {
        //     // 实时输入查询目录
        //     catalogueSrh() {
        //         this.searchDirectoryList();
        //     },
        // },
        mounted() {
            this.getCatalogue();
            // this.getFileList();
            this.querySysOriginalDescriptionAll();
        },
        watch: {
            files: {
                handler() {
                    this.files.forEach((e) => {
                        this.percent = e.percent
                    });
                },
                deep: true
            }
        },
        methods: {
            addArchive() {
                let ids = this.dataListSelections.map(item => {
                    return item.id
                }).join(',')
                this.$refs.addArchive.init(ids)
            },
            queryGetCatalogue() {
                this.pageNo3 = 1
                this.getCatalogue();
            },
            // 查询展览
            getCatalogue() {
                this.exhibitionVal = ""
                this.$axios(this.api.common.queryExhibition, {
                    'name': this.catalogueName,
                    'pageNo': this.pageNo3,
                    'pageSize': this.pageSize3,
                }, 'get').then(data => {
                    if (data && data.status) {
                        this.exhibitionOpt = data.data.records;
                        this.total3 = parseInt(data.data.total)
                        if (this.exhibitionOpt.length > 0) {
                            this.exhibitionVal = this.exhibitionOpt[0].id
                            this.exhibitionName = this.exhibitionOpt[0].exhibitionName
                            this.catalogueList = []
                            // this.exhibitionShuju={}
                            this.getDirectory();
                        }
                    }
                })
            },
            searchDirectoryList() {
                this.pageNo = 1;
                // this.fileList = [];
                this.getDirectory();
            },
            // 查询目录
            getDirectory() {
                // if (this.exhibitionVal) {
                let that = this;
                this.directoryId = ""
                this.exhibitionShuju = {}
                this.exhibitionOpt.forEach(function (v) {
                    if (v.id == that.exhibitionVal) {
                        that.exhibitionLabel = v
                    }
                })
                this.$axios(this.api.mana.queryAllByLimit, {
                    'exhibitionId': this.exhibitionVal,
                    'directoryName': this.catalogueSrh,
                    "publishState": 0,
                    'pageNo': this.pageNo,
                    'pageSize': this.pageSize,
                }, 'get').then(data => {
                    if (data && data.status) {
                        this.catalogueList = data.data.records
                        this.total = parseInt(data.data.total)
                        if (this.catalogueList.length > 0) {
                            this.exhibitionShuju.id = this.catalogueList[0].id
                            this.exhibitionShuju.directoryName = this.catalogueList[0].directoryName
                            this.directoryId = this.catalogueList[0].id
                        }
                        this.total2 = 0
                        this.fileList = [];
                        this.pageNo2 = 1
                        this.getFileList()
                    }
                })
                // }
            },
            //目录点击
            handleCurrentChange(val) {
                this.$refs.catalogueTable.setCurrentRow(val);
                this.directoryId = val.id
                this.searchFileList()
            },
            searchFileList() {
                this.pageNo2 = 1;
                this.getFileList();
            },
            toHump(name) {
                return name.replace(/\_(\w)/g, function (all, letter) {
                    return letter.toUpperCase();
                });
            },
            querySysOriginalDescriptionAll() {
                let that = this;
                this.$axios(this.api.catalogue.querySysOriginalDescriptionAll, {
                    flag: 0,
                    onDetails: 1
                }, 'get').then(data => {
                    if (data) {
                        this.config = data
                        this.config.forEach(function (v) {
                            v.fieldEnname = that.toHump(v.fieldEnname)
                            if (v.fieldEnname == 'updateId') {
                                v.fieldEnname = 'updateName'
                            }
                            if (v.fieldEnname == 'createId') {
                                v.fieldEnname = 'createName'
                            }
                            // that.$set(that.inputForm,v.fieldEnname,"")
                        })
                    }
                })
            },
            // 查询原文
            getFileList() {
                if (this.directoryId !== '') {
                    this.txt = "拼命加载中"
                    this.loading = true
                    this.$axios(this.api.common.queryAllByLimitReorganize, {
                        'directoryId': this.directoryId,
                        "name": this.fileSrh,
                        "delFlag": 0,
                        'pageNo': this.pageNo2,
                        'pageSize': this.pageSize2,
                        'flag': 0,
                        'onDetails': 1,
                    }, 'get').then(data => {
                        if (data && data.status) {
                            this.fileList = data.data.records
                            this.rows = JSON.parse(JSON.stringify(data.data.records))
                            this.total2 = parseInt(data.data.total)
                            this.loading = false
                            this.$refs.tableBox.doLayout();
                        }
                    })
                }
            },
            //查看日志
            getLog() {
                this.$refs.reorganizeLog.init('1')
            },
            refreshArchives(saveFlag) {
                this.flag = this.$refs.tableBox.store.states.isAllSelected;
                if (this.flag) {
                    if (this.pageNo2 != 1 && saveFlag != 1) {
                        this.pageNo2 = this.pageNo2 - 1
                    }
                }
                this.getFileList()
            },
            //归档
            archive() {
                let ids = this.dataListSelections.map(item => {
                    return item.id
                }).join(',')
                this.$refs.getTemplate.init('archive', ids, this.dataListSelections)
                // this.$confirm(`确定归档所选项吗?`, '提示', {
                //     confirmButtonText: '确定',
                //     cancelButtonText: '取消',
                //     type: 'warning'
                // }).then(() => {
                //     this.log.operType = '12'
                //     this.$axios(this.api.common.reorganizeArchive, {
                //         'ids':ids,
                //     }, 'put').then(data => {
                //         if (data && data.status) {
                //             this.log.operSystem = '0'
                //             this.$message.success("已归档")
                //             // this.getFileList();
                //         }else{
                //             this.log.operSystem = '1'
                //         }
                //         this.logSave(this.dataListSelections);
                //     })
                // })
            },
            //一键归档
            batchArchive() {
                let that = this;
                let directoryName = '';
                this.catalogueList.forEach(function (v) {
                    if (that.directoryId == v.id) {
                        directoryName = v.directoryName + "下的所有档案"
                    }
                })
                this.$refs.getTemplate.init('batchArchive', null, null, this.directoryId, directoryName)
                // this.$confirm(`确定归档目录下所有文档吗?`, '提示', {
                //     confirmButtonText: '确定',
                //     cancelButtonText: '取消',
                //     type: 'warning'
                // }).then(() => {
                //     this.log.operType = '13'
                //     this.$axios(this.api.common.reorganizeKeyArchive, {
                //         'uploadTarget':this.directoryId,
                //     }, 'put').then(data => {
                //         if (data && data.status) {
                //             this.log.operSystem = '0'
                //             this.$message.success("已归档")
                //             // this.getFileList();
                //         }else{
                //             this.log.operSystem = '1'
                //         }
                //         this.logSave3()
                //     })
                // })
            },
            //一键归档，新增日志
            logSave3() {
                let that = this;
                this.catalogueList.forEach(function (v) {
                    if (that.directoryId == v.id) {
                        that.log.operTerm = v.directoryName + "下的所有档案"
                    }
                })
                // this.$axios(this.api.common.batchInsertLogin, this.log, 'post').then(data => {
                    this.getFileList()
                // })
            },
            rowClick(currentRow) {
                this.row = currentRow;
            },
            // 获取上传数据
            getDataList(data) {
                if (data.state == 0) {
                    this.log.operType = '7';
                    this.percentage = 100;
                    this.dialogVisible = false
                    this.fileVersionHistory.fileId = this.row.id
                    this.fileVersionHistory.url = data.data[0].url
                    this.fileVersionHistory.fileFormat = data.data[0].type
                    this.fileVersionHistory.fileSize = data.data[0].size
                    this.fileVersionHistory.fileName = data.data[0].name
                    this.fileVersionHistory.actualPath = data.data[0].actualPath
                    this.$axios(this.api.common.FileVersionHistorySave, this.fileVersionHistory, 'post').then(data => {
                        if (data.status) {
                            this.$message.success("替换成功")
                            this.log.operSystem = '0'
                        } else {
                            this.$message.error(data.msg)
                            this.log.operSystem = '1'
                        }
                        this.logSave2(this.row, this.fileVersionHistory.fileName)
                    })
                    this.uploadRequire.list = [];
                }
            },
            // 改变顺序号
            handleChangeOrder(row) {
                let oldValue = ''
                this.rows.forEach(function (v) {
                    if (v.id == row.id) {
                        oldValue = v.sequenceNumber
                    }
                })
                if (row) {
                    this.txt = "正在重新排列顺序号，请稍后~"
                    this.loading = true
                    this.log.operType = '9'
                    this.log.operTerm = row.fileName
                    this.$axios(this.api.common.reorganizeSetOrder, {
                        id: row.id,
                        sequenceNumber: row.sequenceNumber,
                        oldSequenceNumber: oldValue,
                        uploadTarget: row.uploadTarget
                    }, 'put').then(data => {
                        if (data.status) {
                            this.$message.success("顺序号排列完成")
                            // this.getFileList()
                            this.log.operSystem = '0'
                        } else {
                            this.$message.error(data.msg)
                            this.log.operSystem = '1'
                        }
                        // this.$axios(this.api.common.logSave, this.log, 'post').then(data => {
                            // if(data.status){
                            this.getFileList()
                            // }
                        // })
                    })
                }

            },
            // 关联
            handelRelevance(row) {
                // this.activeNames=[]
                this.$refs.relevance.init(row, this.exhibitionLabel)
            },
            // 详情
            view(id) {
                this.$refs.fileDetails.init(id, 'reorganize')
            },
            // 下载
            download(row) {
                let rows = [];
                if (row) {
                    rows = [row]
                } else {
                    rows = this.dataListSelections
                }
                let that = this;
                this.log.operType = '19'
                this.log.operSystem = '1'
                rows.forEach(function (v) {
                    that.log.operTerm = v.fileName
                    const a = document.createElement('a')
                    //   let url = baseUrl + binding.value // 若是不完整的url则需要拼接baseURL
                    const url = v.url;  // 完整的url则直接使用
                    // 这里是将url转成blob地址，
                    fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
                        a.href = URL.createObjectURL(blob)
                        a.download = v.fileName + "." + v.fileFormat // 下载文件的名字
                        // a.download = url.split('/')[url.split('/').length -1] //  // 下载文件的名字
                        document.body.appendChild(a)
                        a.click()
                        that.log.operSystem = '0'
                        // that.$axios(that.api.common.logSave, that.log, 'post')
                    })

                })
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 修改、移动、批量修改、批量移动
            edit(method, row) {
                let rows = [];
                if (row) {
                    rows = [row]
                } else {
                    rows = this.dataListSelections
                }
                this.$refs.editForm.init(method, rows, 'reorganize', this.config)
            },
            // 删除、批量删除
            del(row) {
                // 批量删除多个id用逗号隔开
                let ids = ''
                let sequenceNumbers = ""
                let uploadTarget = ''
                if (row) {
                    ids = row.id
                    sequenceNumbers = row.sequenceNumber
                    uploadTarget = row.uploadTarget
                } else {
                    ids = this.dataListSelections.map(item => {
                        return item.id
                    }).join(',')
                    sequenceNumbers = this.dataListSelections.map(item => {
                        return item.sequenceNumber
                    }).join(',')
                    this.dataListSelections.forEach(function (v) {
                        uploadTarget = v.uploadTarget
                    })
                    // uploadTarget = this.dataListSelections.map(item => {
                    //     return item.uploadTarget
                    // })
                }
                this.$confirm(`确定删除所选项吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.txt = "正在重新排列顺序号，请稍后~"
                    this.loading = true;
                    this.$axios(this.api.common.fileCollectBatchUpdateById, {
                        'ids': ids,
                        "delFlag": 1,
                        "sequenceNumbers": sequenceNumbers,
                        "uploadTarget": uploadTarget
                    }, 'put').then(data => {
                        this.log.operType = '1'
                        if (data.status) {
                            this.log.operSystem = '0'
                            this.$message.success("顺序号排列完成，删除成功！")
                        } else {
                            this.$message.error(data.msg)
                            this.log.operSystem = '1'
                        }
                        this.getFileList()
                        this.logSave(this.dataListSelections, row)
                        this.loading = false
                    })
                })
            },
            //新增日志
            logSave(dataListSelections, row) {
                if (row) {
                    // this.log.operTerm = row.fileName
                    // this.$axios(this.api.common.logSave, this.log, 'post').then(data => {
                        this.getFileList()
                    // })
                } else {
                    // let that = this;
                    // let batchList = []
                    // dataListSelections.forEach(function (v) {
                    //     let log = JSON.parse(JSON.stringify(that.log))
                    //     log.operTerm = v.fileName
                    //     batchList.push(log)
                    // })
                    // this.$axios(this.api.common.batchInsertLogin, batchList, 'post').then(data => {
                        if (dataListSelections.length == this.fileList.length) {
                            this.pageNo2 = 1
                        }
                        this.getFileList()
                    // })
                }

            },
            //新增日志
            logSave2(row, fileName) {
                if (row) {
                    // this.log.operTerm = row.fileName + "【替换成】" + fileName
                    // this.$axios(this.api.common.logSave, this.log, 'post').then(data => {
                        this.getFileList()
                    // })
                }
            },
            // 设置
            setTb() {
                this.setShow = true;
            },
            // 接收子组件（排序弹窗）传过来的值
            getTbList(data, state) {
                this.config = data;
                this.getFileList();
                this.setShow = state;
            },
            //导出目录
            exportReorganize() {
                // this.activeNames=[]
                if (this.exhibitionVal) {
                    this.zlzsExportExcel(this.api.common.fileCollectExport, this.exhibitionVal)
                }
            },
            // 目录每页数
            sizeChangeHandle(val) {
                this.pageSize = val;
                this.pageNo = 1;
                this.getCatalogue();
            },
            // 目录当前页
            currentChangeHandle(val) {
                this.pageNo = val;
                this.getCatalogue();
            },
            // 原文每页数
            sizeChangeHandle2(val) {
                this.pageSize2 = val;
                this.pageNo2 = 1;
                this.getFileList();
            },
            // 原文当前页
            currentChangeHandle2(val) {
                this.pageNo2 = val;
                this.getFileList();
            },

            //展览选中
            catalogueSelect(item) {
                this.catalogueShuju = item;
                // this.catalogueNames = [];
                this.exhibitionName = item.exhibitionName
                this.exhibitionVal = item.id
                this.pageNo = 1
                this.getDirectory();
            },

            //展览查询
            catalogueQuery() {

            },

            //目录选中
            exhibitionSelect(item) {
                this.exhibitionShuju = item;
                this.directoryId = item.id
                this.pageNo2 = 1
                this.getFileList()
                // this.activeNames = [];
            },

            //目录查询
            directoryQuery() {

            },
            exhibitionOn() {
                if (this.pageNo3 == 1) {
                    return false
                }
                this.pageNo3 -= 1
                this.pageNo = 1
                this.getCatalogue();
            },
            exhibitionTo() {
                if (this.pageNo3 * this.pageSize3 >= this.total3) {
                    return false
                }
                this.pageNo3 += 1
                this.pageNo = 1
                this.getCatalogue();
            },
            directoryOn() {
                if (this.pageNo == 1) {
                    return false
                }
                this.pageNo -= 1
                this.getDirectory();
            },
            directoryTo() {
                if (this.pageNo * this.pageSize >= this.total) {
                    return false
                }
                this.pageNo += 1
                this.getDirectory();
            },
            //上传进度弹窗关闭
            handleClose() {
                this.$message.error("正在替换文件，请勿操作");
            },
            // 获取上传进度
            getPercentage(data) {
                this.dialogVisible = true;
                if (parseInt(data.percentage) >= 99) {
                    this.percentage = 99;
                } else {
                    this.percentage = parseInt(data.percentage);
                }
            },
        }
    }
</script>

<style scoped>
    #reorganize {
        height: 100%;
        overflow-y: auto !important;
    }

    /*展览*/
    .table3.el-table >>> .cell {
        text-overflow: initial !important;
    }

    .exhibition {
        margin-top: 20px;
        padding: 0px 16px;
        box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.4);
        border: 1px solid rgba(208, 208, 208, 100);
    }

    .exhibition-title {
        display: flex;
        align-items: center;
        color: black;
        font-size: 20px;
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .exhibition-select {
        margin-left: 20px;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .aidjbf {
        display: flex;
    }

    .checkBox {
        margin: 0 10px;
        width: 20%;
        padding: 12px;
        border-radius: 4px;
        border: 1px solid rgba(187, 187, 187, 100);
    }

    .hui {
        color: rgba(208, 208, 208, 100);
    }

    .blue {
        color: #3E8DF7;
    }

    .select {
        border: 1px solid #3E8DF7;
    }

    .wenojsnd {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .lbaiogao {
        margin-top: 20px;
        width: 100%;
        box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.4);
        border: 1px solid rgba(215, 215, 215, 100);
    }
</style>
