<template>
    <el-dialog
            title="选择档号规则"
            :close-on-click-modal="false"
            append-to-body
            :visible.sync="visible">
        <el-form size="small" :model="inputForm" :rules="dataRule" v-loading="loading"  ref="inputForm" @keyup.enter.native="doSubmit()"
                 label-width="120px" @submit.native.prevent>
            <el-form-item label="档号规则：" prop="oldSequenceNumber">
                <el-select v-model="inputForm.rulesId" placeholder="请选择" style="width: 100%">
                    <el-option
                            v-for="item in options"
                            :key="item.id"
                            :label="item.templateName"
                            :value="item.id">
                    </el-option>
                </el-select>
<!--                <el-input v-model="inputForm.templateName" placeholder="请输入模板名"></el-input>-->
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">取消</el-button>
      <el-button size="small" type="primary" @click="doSubmit()" v-noMoreClick>确定</el-button>
    </span>
    </el-dialog>
</template>

<script>
    export default {
        data () {
            return {
                visible: false,
                loading: false,
                options:[],
                inputForm: {
                    rulesId: '',
                },
                dataRule: {
                    rulesId: [
                        {required: true, message: '档号规则不能为空', trigger: 'blur'}
                    ]
                },
                list:"",

            }
        },
        methods: {
            init (list) {
                this.visible = true
                this.loading = true
                this.$axios(this.api.infor.queryAllByTemplateType, {}, "get").then(data => {
                    this.loading = false
                    if (data && data.status) {
                        this.options=data.data
                    }
                })
                this.list=list
                this.$nextTick(() => {
                    this.$refs['inputForm'].resetFields()
                })
            },

            // 表单提交
            doSubmit () {
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        this.$axios(this.api.common.archiveTaskSave, {
                            'ids':this.list,
                            "rulesId":this.inputForm.rulesId
                        }, 'post').then(data => {
                            if (data && data.status) {
                                this.$message.success(data.msg)
                            }else{
                                this.$message.error(data.msg)
                            }
                            this.visible = false
                            this.$emit('refreshDataList')
                        })
                    }
                })
            }
        }
    }
</script>
