<template>
  <div>
    <el-dialog :title="title" width="60%" :close-on-click-modal="false" :visible.sync="visible" v-if="visible">
      <el-menu :default-active="tabIndex" mode="horizontal" @select="handleSelectTab">
        <el-menu-item index="0">文件预览</el-menu-item>
        <el-menu-item index="1">文件详情</el-menu-item>
        <el-menu-item index="3" v-if="type == 'reorganize'">版本历史</el-menu-item>
        <el-menu-item index="4"
          v-if="type == 'reorganize' && (userInfo.id == fileDetailsForm.createId || fileDetailsForm.admin)">操作权限</el-menu-item>
        <el-menu-item index="2">文档元属性</el-menu-item>
      </el-menu>
      <!--文件预览-->
      <div v-if="tabIndex == '0'" class="m_t1" v-loading="loading2">
        <div style="width: 100%;height:calc(100vh - 320px)">
          <iframe v-if="!loading2"
            :src='this.$pdfPreviewUrl() + "onlinePreview?url=" + $Base64.encode(fileDetailsForm.url.replaceAll("\\", "/")) + "&mediaFlag=1"'
            frameborder="0" width="100%" height="100%"></iframe>
        </div>
      </div>
      <!--文件详情-->
      <div v-if="tabIndex == '1'" class="m_t1">
        <el-form class="dialogForm" size="small" :model="fileDetailsForm" :rules="dataRule" ref="fileDetailsForm"
          label-width="120px" :disabled="type == 'recycle'">
          <el-form-item :label="item.recordName + '：'" :prop="item.fieldEnname" v-for="(item, index) in config"
            :key="index">
            <el-input v-if="item.detailsShowType == '0'" v-model.trim="fileDetailsForm[item.fieldEnname]"
              :placeholder="'请输入' + item.recordName" :maxlength="item.fieldEnname == 'fileName' ? 200 : 66"></el-input>
            <el-select :disabled="type == 'reorganize'"
              v-if="item.detailsShowType == '1' && item.fieldEnname == 'quanzongNo'"
              v-model="fileDetailsForm[item.fieldEnname]" style="width:100%" @change="manaList(0)" placeholder="请选择">
              <el-option v-for="item in exhibitionList" :key="item.id" :label="item.exhibitionName" :value="item.id">
              </el-option>
            </el-select>
            <el-select v-if="item.detailsShowType == '1' && item.fieldEnname == 'uploadTarget'"
              v-model="fileDetailsForm[item.fieldEnname]" style="width:100%" placeholder="请选择">
              <el-option v-for="item in catalogueList" :key="item.id" :label="item.directoryName" :value="item.id">
              </el-option>
            </el-select>
            <el-select v-if="item.detailsShowType == '1' && item.fieldEnname == 'fieldName8'"
              v-model="fileDetailsForm[item.fieldEnname]" style="width:100%" placeholder="请选择">
              <el-option v-for="item in $dictUtils.getDictList('file_type')" :key="item.value" :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <el-date-picker v-if="item.detailsShowType == '2'" v-model="fileDetailsForm[item.fieldEnname]" type="year"
              style="width:100%" placeholder="选择年度" value-format="yyyy"></el-date-picker>
            <el-date-picker v-if="item.detailsShowType == '3'" v-model="fileDetailsForm[item.fieldEnname]" type="datetime"
              style="width:100%" placeholder="选择日期" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
          </el-form-item>
        </el-form>
      </div>
      <!--文档元属性-->
      <div v-if="tabIndex == '2'" class="m_t1">
        <el-form size="small" :model="fileDetailsForm" ref="fileDetailsForm" label-width="120px" style="width:70%"
          disabled>
          <el-form-item label="文件大小：" prop="fileSize">
            <el-input v-model="(fileDetailsForm.fileSize / 1024 / 1024).toFixed(2) + 'M'"></el-input>
          </el-form-item>
          <el-form-item label="文件格式：" prop="fileFormat">
            <el-input v-model="fileDetailsForm.fileFormat"></el-input>
          </el-form-item>
          <el-form-item label="更新人：" prop="updateName">
            <el-input v-model="fileDetailsForm.updateName"></el-input>
          </el-form-item>
          <el-form-item label="更新时间：" prop="updateDate">
            <el-input v-model="fileDetailsForm.updateDate"></el-input>
          </el-form-item>
          <el-form-item label="更新人IP：" prop="updateIp">
            <el-input v-model="fileDetailsForm.updateIp"></el-input>
          </el-form-item>
          <el-form-item label="创建人：" prop="createName">
            <el-input v-model="fileDetailsForm.createName"></el-input>
          </el-form-item>
          <el-form-item label="创建时间：" prop="createDate">
            <el-input v-model="fileDetailsForm.createDate"></el-input>
          </el-form-item>
          <el-form-item label="创建人IP：" prop="createIp">
            <el-input v-model="fileDetailsForm.createIp"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <!--版本历史-->
      <div v-if="tabIndex == '3'" class="m_t1">
        <el-table :data="dataList" v-loading="loading" size="small" height="calc(100% - 130px)" class="table">
          <el-table-column prop="version" label="版本号" sortable width="80">
            <template slot-scope="scope" v-if="scope.row.moveFlag == 0">
              {{ scope.row.version }}
            </template>
          </el-table-column>
          <el-table-column prop="updateName" label="更新人" show-overflow-tooltip />
          <el-table-column prop="updateDate" label="更新时间" width="150" sortable show-overflow-tooltip />
          <el-table-column prop="fileName" label="更新内容" show-overflow-tooltip width="200">
            <template slot-scope="scope">
              <span v-if="scope.row.moveFlag == 0">{{ scope.row.fileName }}</span>
              <span v-else>
                从{{ scope.row.exhibitionName }}的{{ scope.row.directoryName }}
                【移动至】{{ scope.row.oldExhibitionName }}的{{ scope.row.oldDirectoryName }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="createIp" label="更新IP" show-overflow-tooltip />
          <el-table-column prop="mainVersion" label="是否主版本">
            <template slot-scope="scope" v-if="scope.row.moveFlag == 0">
              <el-tag :type="scope.row.mainVersion === 1 ? 'success' : 'danger'">{{ scope.row.mainVersion === 1 ? '是' :
                '否' }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="200">
            <template slot-scope="scope">
              <el-button type="text" size="mini" @click="restoreRow(scope.row)"
                v-if="scope.row.moveFlag == 1">还原</el-button>
              <el-button type="text" size="mini" @click="setMain(scope.row)"
                v-if="scope.row.moveFlag == 0 && scope.row.mainVersion == 0">设为主版本</el-button>
              <el-button type="text" size="mini" @click="downloadRow(scope.row)"
                v-if="scope.row.moveFlag == 0">下载</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="sizeChangeHandle" @current-change="currentChangeHandle" :current-page="pageNo"
          :page-sizes="[10, 20, 50, 100]" :page-size="pageSize" :total="total" background
          layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      </div>
      <!--操作权限-->
      <div v-if="tabIndex == '4'" class="m_t1">
        <el-form size="small" :model="fileDetailsForm" :rules="dataRule" ref="authorizationForm" label-width="120px"
          style="width:70%">
          <el-form-item label="操作类型：" prop="fieldName2">
            <el-select v-model="fileDetailsForm.fieldName2" style="width:100%" placeholder="请选择">
              <el-option v-for="item in $dictUtils.getDictList('operation_type')" :key="item.value" :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="操作人员：" prop="operationId" v-if="fileDetailsForm.fieldName2 == 2">
            <el-select v-model="fileDetailsForm.operationId" style="width:100%" multiple placeholder="请选择"
              @change="changeUser">
              <el-option v-for="item in editorialList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="m_t1 text_center">
        <el-button size="small" @click="clickOpen">全屏预览</el-button>
        <el-button size="small" type="primary" @click="print">打印</el-button>
        <el-button size="small" @click="downloadRow(fileDetailsForm)">下载</el-button>
        <!--                <el-button size="small" icon="el-icon-lock"  v-if="!fileDetailsForm.fieldName3" @click="lock(0)"  v-noMoreClick>锁定</el-button>-->
        <!--                <el-button size="small" icon="el-icon-unlock" v-else  type="danger" @click="lock(1)"  v-noMoreClick>解锁</el-button>-->
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="visible = false">取消</el-button>
        <el-button size="small" v-if="type !== 'recycle' && (tabIndex == '1' || tabIndex == '4')" type="primary"
          @click="save()" v-noMoreClick>保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import pdf from "vue-pdf";
import mammoth from "mammoth";
import { formatDate } from "../../../../../utils/publicUtile";

export default {
  components: { pdf, mammoth },
  data() {
    return {
      vHtml: "",
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      title: '',
      type: 'reorganize',  // gather收集、reorganize整编、recycle回收站
      tabIndex: "0",
      visible: false,
      // // 预览
      // previewData: {
      fileType: 'file',
      //     // url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      //     // url: 'https://cms.cnc.blzstatic.cn/cms/gallery/4G8KGRWWG7FS1557732437703.mp4',
      //     // url: 'https://hnst.zyddbai.com/9688152ce5580a6cfbcc073fadfa7c39.pdf',
      //     url: 'http://downsc.chinaz.net/Files/DownLoad/sound1/201906/11582.mp3',
      // },
      currentPage: 0, // pdf文件页码
      pageCount: 0, // pdf文件总页数
      // 文件详情
      fileDetailsForm: {
        id: "",
        url: "",
        fileSize: "",
        fileFormat: "",
        updateName: "",
        updateDate: "",
        updateIp: "",
        createName: "",
        createDate: "",
        createIp: "",
        createId: "",
        admin: "",
        fieldName2: "",
        fieldName3: "",
        operationId: [],
      },
      operationId: [],
      fileDetailsForm2: {},
      dataRule: {
        fileName: [{ required: true, message: '文件名不能为空', trigger: 'blur' }],
        pyear: [{ required: true, message: '年度不能为空', trigger: 'blur' }],
        writtenDate: [{ required: true, message: '成文日期不能为空', trigger: 'blur' }],
        fieldName5: [{ required: true, message: '存放位置不能为空', trigger: 'blur' }],
        fieldName7: [{ required: true, message: '文件标识号不能为空', trigger: 'blur' }],
        fieldName8: [{ required: true, message: '文件类型不能为空', trigger: 'blur' }],
        classification: [{ required: true, message: '密级不能为空', trigger: 'blur' }],
        safekeepDate: [{ required: true, message: '保管期限不能为空', trigger: 'blur' }],
        respoPerson: [{ required: true, message: '责任者不能为空', trigger: 'blur' }],
        operationId: [{ required: true, message: '操作人不能为空', trigger: 'blur' }]
      },
      catalogueList: [],//目录
      exhibitionList: [],//展览

      // 版本历史
      dataList: [],
      loading: false,
      loading2: false,
      pageNo: 1,
      pageSize: 10,
      total: 0,
      previewList: [],
      editorialList: [],
      log: {
        operModular: "1",//模块
        operType: "8",//类型：主版本
        operTerm: "",//内容
        operSystem: "1",//结果
      },
      fileVersionHistory: {
        fileId: "",
        primaryId: "",//移动前的所属展览id
        secondarId: "",//移动前的所属目录id
        moveFlag: 1,//移动
        newPrimaryId: "",//移动后的所属展览id
        newSecondarId: "",//移动后的所属目录id
      },
      config: [],//详情页配置
      detailsLog: {
        operModular: "0",//模块
        operType: "14",//类型：详情更新
        operTerm: "",//内容
        operSystem: "1",//结果
      },
      filePath: "",
      id: ""
    }
  },
  mounted() {

  },
  methods: {
    clickOpen() {
      window.open(this.$pdfPreviewUrl() + 'onlinePreview?url=' + this.$Base64.encode(this.fileDetailsForm.url.replaceAll('\\', '/')) + "&mediaFlag=0")
    },
    lock(type) {
      this.fileDetailsForm.fieldName3 = ""
      if (type == 0) {//锁定
        this.fileDetailsForm.fieldName3 = this.userInfo.id
      }
      console.log(this.fileDetailsForm);
      this.$axios(this.api.common.lock, this.fileDetailsForm, 'put').then(data => {
        if (data && data.status) {
          this.$message.success(data.data)
        }
      })
    },
    word() {
      let vm = this;
      vm.wordURL = this.fileDetailsForm.url;
      const xhr = new XMLHttpRequest();
      xhr.open("get", this.wordURL, true);
      xhr.responseType = "arraybuffer";
      xhr.onload = function () {
        if (xhr.status == 200) {
          mammoth
            .convertToHtml({
              arrayBuffer: new Uint8Array(xhr.response),
            })
            .then(function (resultObject) {
              vm.vHtml = resultObject.value;
            });
        }
      };
      xhr.send();
    },
    init(id, type) {
      this.type = type;
      this.tabIndex = '0';
      this.id = id
      this.fileDetailsForm.url = ""
      this.loading2 = true
      // this.$nextTick(() => {
      // this.$refs.fileDetailsForm.resetFields()
      this.querySysOriginalDescriptionAll(id);//详情页
      this.exhibitionInAll();//所有展览

      if (this.type == "reorganize") {//文件整编
        this.reorganize(id)
      }
      // })
    },
    userList(id) {
      this.$axios(this.api.auth.sysuserQueryAllList, {
        'current': this.pageNo,
        'size': this.pageSize,
        orderBy: '',
        name: '',
        loginName: '',
      }, 'get').then(data => {
        if (data && data.status) {
          this.editorialList = data.data.records
          this.permissionsUser(id);
        }
      })
    },
    changeUser(val) {
      console.log(this.fileDetailsForm.operationId);
    },
    permissionsUser(id) {
      let that = this;
      that.fileDetailsForm.operationId = []
      // console.log(that.fileDetailsForm.operationId);
      // that.operationId = []
      that.$axios(that.api.common.authorityList, { fileId: id }, 'get').then(data => {
        if (data && data.status) {
          data.data.forEach(function (v) {
            if (v.userId) {
              that.fileDetailsForm.operationId.push(v.userId)
            }
            // that.fileDetailsForm.operationId.push(v.userId)
          })
          // this.$set(that.fileDetailsForm,'operationId',that.operationId)
          // that.fileDetailsForm.operationId = that.operationId
        }
        this.fileDetailsForm2 = JSON.parse(JSON.stringify(this.fileDetailsForm))
      })
      console.log(that.fileDetailsForm.operationId);
    },
    //详情页配置
    querySysOriginalDescriptionAll(id) {
      let that = this;
      this.$axios(this.api.catalogue.querySysOriginalDescriptionAll, {
        flag: 0,
        onDetails: 0
      }, 'get').then(data => {
        if (data) {
          this.config = data
          this.config.forEach(function (v) {
            v.fieldEnname = that.toHump(v.fieldEnname)
            that.$set(that.fileDetailsForm, v.fieldEnname, "")
          })
          // console.log("详情页配置：",this.config);
          this.fileCollectQueryById(id);
        }
      })
    },
    toHump(name) {
      return name.replace(/\_(\w)/g, function (all, letter) {
        return letter.toUpperCase();
      });
    },
    //文件信息
    fileCollectQueryById(id) {
      this.$axios(this.api.common.fileCollectQueryById, {
        'id': id,
        'flag': 0,
        'onDetails': 0
      }, 'get').then(data => {
        if (data.status) {
          this.visible = true
          this.title = data.data.fileName;
          // console.log(data);
          this.fileDetailsForm = this.recover(this.fileDetailsForm, data.data)
          this.filePath = JSON.parse(JSON.stringify(this.fileDetailsForm.url))
          console.log(this.fileDetailsForm.url);
          this.manaList();//根据展览id查询所有目录
          // this.fileDetailsForm.fileSize=(this.fileDetailsForm.fileSize/1024/1024).toFixed(2)
          if (this.fileDetailsForm.quanzongNo == 0) {
            this.fileDetailsForm.quanzongNo = ""
          }
          this.userList(id);//所有用户
          //原来的文件对象，用于判断是否修改，记录日志

          // console.log(this.fileDetailsForm);
          this.loading2 = false
          this.getFileSrc()
          // console.log(this.fileType);
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    //展览
    exhibitionInAll() {
      this.$axios(this.api.common.queryExhibition2, {}, 'get').then(data => {
        if (data.status) {
          this.exhibitionList = data.data;
        }
      })
    },
    //目录
    manaList(type) {
      if (type == 0) {
        this.fileDetailsForm.uploadTarget = ''
      }
      this.$axios(this.api.mana.selectSysDirectory, {
        "exhibitionId": this.fileDetailsForm.quanzongNo
      }, 'get').then(data => {
        if (data.status) {
          this.catalogueList = data.data;
        }
      })
    },
    //版本信息
    reorganize(id) {
      this.$axios(this.api.common.versionReorganize, {
        "fileId": id,
        'pageNo': this.pageNo,
        'pageSize': this.pageSize,
      }, 'get').then(data => {
        if (data.status) {
          this.dataList = data.data.records;
          let that = this;
          this.total = parseInt(data.data.total)
          this.dataList.forEach(function (v) {
            if (v.url) {
              console.log(v.url);
              that.$set(v, "filePath", v.url);
              v.url = that.$globalUrl() + v.url.substring(v.url.indexOf("\\"), v.url.length)

            }
          })
          // console.log(this.dataList);
        }
      })
    },
    // 保存
    save() {
      if (this.tabIndex == '1') {
        this.$refs['fileDetailsForm'].validate((valid) => {
          if (valid) {
            this.puile()
          }
        })

      } else if (this.tabIndex == '4') {
        this.$refs['authorizationForm'].validate((valid) => {
          if (valid) {
            this.puile()
          }
        })
      } else {
        this.puile()
      }
    },
    puile() {
      if (this.type == 'reorganize') {
        this.detailsLog.operModular = 1
      } else {
        this.detailsLog.operModular = 0
      }
      if (!this.$publicUtile.isEqual(this.fileDetailsForm, this.fileDetailsForm2)) {
        this.$axios(this.api.common.fileCollectUpdateById, this.fileDetailsForm, 'put').then(data => {
          let log = [];
          if (data.status) {
            this.detailsLog.operSystem = '0'
            let update = []
            for (let key of Object.keys(this.fileDetailsForm2)) {
              let mealName = this.fileDetailsForm2[key];
              for (let key2 of Object.keys(this.fileDetailsForm)) {
                let mealName2 = this.fileDetailsForm[key2];
                if (key === key2 && mealName !== mealName2) {
                  let obj = {
                    "key": key,
                    "old": mealName,
                    "new": mealName2,
                  }
                  update.push(obj)
                }
              }
            }
            this.config.forEach(function (v) {
              update.forEach(function (f) {
                if (v.fieldEnname == f.key) {
                  log.push(v.recordName + "从" + f.old + "更新为" + f.new)
                }
              })
            })
            this.$message.success(data.msg)
            this.$emit("refreshDataList")
          }
        })
      } else {
        this.visible = false
      }
    },
    //打印
    print() {
      let log = {
        operModular: "0",//模块
        operType: "16",//类型
        operTerm: this.fileDetailsForm.fileName,//内容
        operSystem: "1",//结果:失败
      }
      if (this.type == "reorganize") {
        log.operModular = "1"
      }
      if (this.type == "recycle") {
        log.operModular = "2"
      }
      if (this.fileType == 'pdf' || this.fileType == 'image') {
        this.$print({
          printable: this.fileDetailsForm.url,
          type: this.fileType,
          header: null,
          targetStyles: ['*'],
          style: "@page {margin:0 10mm}"
        })
        log.operSystem = "0"
      } else {
        this.$message.error("仅支持图片和pdf格式文件！")
      }
    },
    // pdf加载时
    loadPdfHandler(e) {
      this.currentPage = 1 // 加载的时候先加载第一页
    },
    // 改变PDF页码,val传过来区分上一页下一页的值,0上一页,1下一页
    changePdfPage(val) {
      if (val === 0 && this.currentPage > 1) {
        this.currentPage--
      }
      if (val === 1 && this.currentPage < this.pageCount) {
        this.currentPage++
      }
    },
    getFileSrc() {
      this.fileType = "file"
      let fileType = this.fileDetailsForm.fileFormat
      if (fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg' || fileType === 'bmp' || fileType === 'gif'
        || fileType === 'PNG' || fileType === 'JPG' || fileType === 'JPEG' || fileType === 'BMP' || fileType === 'GIF') {
        this.fileType = 'image'
      } else if (fileType === 'pdf' || fileType === 'PDF') {
        this.fileType = 'pdf'
      }
      else if (fileType === 'doc' || fileType === 'docx') {
        this.fileType = 'word'
        // this.fileDetailsForm.url = 'https://view.officeapps.live.com/op/view.aspx?src=' + this.fileDetailsForm.url
        // this.word()
      }
      else if (fileType === 'mp4' || fileType === 'm2v' || fileType === 'mkv') {
        this.fileType = 'video'
      } else if (fileType === 'mp3' || fileType === 'wav' || fileType === 'wmv') {
        this.fileType = 'radio'
      }
    },
    // tab切换
    handleSelectTab(index) {
      this.tabIndex = index;
    },
    // 还原
    restoreRow(row) {
      let fileCollect = {
        id: row.fileId,
        quanzongNo: row.primaryId,
        uploadTarget: row.secondarId
      }
      // console.log(fileCollect);
      this.log.operType = '2'
      this.$axios(this.api.common.fileCollectRestore, fileCollect, 'put').then(data => {
        if (data.status) {
          this.log.operSystem = '0'
          this.$message.success(data.msg)
          this.$emit('refreshDataList')
          this.visible = false
          this.batchInsert(row);
        } else {
          this.log.operSystem = '1'
        }
        row.fileName = this.fileDetailsForm.fileName
      })
    },
    batchInsert(row) {
      let that = this;
      let batchList = []
      that.fileVersionHistory.fileId = row.fileId
      that.fileVersionHistory.primaryId = this.fileDetailsForm.quanzongNo
      that.fileVersionHistory.secondarId = this.fileDetailsForm.uploadTarget
      that.fileVersionHistory.newPrimaryId = row.primaryId
      that.fileVersionHistory.newSecondarId = row.secondarId
      batchList.push(that.fileVersionHistory)
      // console.log(batchList);
      this.$axios(this.api.common.FileVersionHistoryBatchInsert, batchList, 'post').then(data => {
        // console.log("日志：",data);
        // if(data.status){
        // this.getFileList()
        // }
      })
    },
    //设置主版本
    setMain(row) {
      // console.log(row);
      let fileCollect = {
        id: row.id,
        mainVersion: 1,
        fileId: this.fileDetailsForm.id,
        fileFormat: row.fileFormat,
        url: row.filePath
      }
      console.log(fileCollect);
      this.$axios(this.api.common.FileVersionHistoryUpdateById, fileCollect, 'put').then(data => {
        this.log.operType = '8'
        if (data.status) {
          this.log.operSystem = '0'
          this.$message.success(data.msg)
          this.$emit('refreshDataList')
          this.visible = false
        } else {
          this.log.operSystem = '1'
        }
      })
    },

    // 下载
    downloadRow(row) {
      let log = {
        operModular: "0",//模块
        operType: "19",//类型
        operTerm: row.fileName,//内容
        operSystem: "1",//结果:失败
      }
      if (this.type == "reorganize") {
        log.operModular = "1"
      }
      if (this.type == "recycle") {
        log.operModular = "2"
      }
      console.log(row);
      const a = document.createElement('a')
      //   let url = baseUrl + binding.value // 若是不完整的url则需要拼接baseURL
      const url = row.url;  // 完整的url则直接使用
      // 这里是将url转成blob地址，
      fetch(url).then(res => res.blob()).then(blob => { // 将链接地址字符内容转变成blob地址
        a.href = URL.createObjectURL(blob)
        // console.log(a.href)
        a.download = row.fileName + "." + row.fileFormat // 下载文件的名字
        // a.download = url.split('/')[url.split('/').length -1] //  // 下载文件的名字
        document.body.appendChild(a)
        a.click()
        log.operSystem = "0"
      })

    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.reorganize(this.id);
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      this.reorganize(this.id);
    },
  }
}
</script>

<style scoped></style>
